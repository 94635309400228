import React from "react"

import { Collapse, Select } from "antd"
import { decodeHTMLEntities } from "../Layout/utils/utils"
const { Panel } = Collapse

import { StructuredData } from "../seo"
import { WithContext, FAQPage } from "schema-dts"

export type FaqData = {
  title: string
  details: {
    answer: string
  }
}

type Props = {
  className?: string
  faqs: FaqData[]
}

const FAQ: React.FC<Props> = ({ className, faqs }) => {
  const structuredData: WithContext<FAQPage> = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqs?.map((faq) => {
      return {
        "@type": "Question",
        name: decodeHTMLEntities(faq.title),
        acceptedAnswer: { "@type": "Answer", text: faq.details.answer },
      }
    }),
  }

  return (
    <div className={`row row--full ${className} faqs`}>
      <div className="row row--center row--no-grid">
        {/* <Collapse defaultActiveKey={["0"]} expandIconPosition="right" ghost> */}
        <StructuredData data={structuredData}>
          <Collapse expandIconPosition="right" ghost>
            {faqs?.map((faq, i) => (
              <Panel header={decodeHTMLEntities(faq.title)} key={i}>
                <div dangerouslySetInnerHTML={{ __html: faq.details.answer }} />
              </Panel>
            ))}
          </Collapse>
        </StructuredData>
      </div>
    </div>
  )
}

export default FAQ
